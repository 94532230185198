export interface IndexedRoute {
  path: string
  locales?: string[]
}

// If locale is not specified, the route will be indexed for all locales
export const indexedRoutes: IndexedRoute[] = [
  { path: '/' },
  { path: '/giftcard/' },
  { path: '/teamevent/' },
  { path: '/partner/' },
  { path: '/partner/pricing/' },
  { path: '/sitemap/' },
  { path: '/faq/' },
  { path: '/terms-and-conditions/', locales: ['de-de'] },
  { path: '/impressum/' },
  { path: '/kits/' },
  { path: '/b2c/private-events/' },
  { path: '/magazine/', locales: ['de-de'] },
]
